.friends-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0em -1em 2em;
  .friend {
    width: 8em;
    @include responsive(small) {
      width: 10em;
    }
    @include responsive(medium) {
      width: 12em;
    }
  }
  .logo {
    @include transition(fill);
    fill: c(bare-text, 0.25);
    fill-rule: evenodd;
  }
  .img {
    filter: grayscale(100%) brightness(35%);
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    &:hover, &:focus {
      filter: none;
    }
  }
}

$friends: (
  agilekoulutus: [#365691,#17763e,#00233d,#000000],
  ainsinoorit: [#60b47a,#057526, #000000],
  ambientia: [#FF2457],
  atostek: [#484D51,#8ABD23,#5AB031],
  bitwise: [#1D1D1B],
  cinia: [#0C2340,#F4364C,#A50034],
  digia: [#D8001F],
  cybercom: [#025599],
  eficode: [#100f0d,#fff],
  elisa: [#0012B4],
  eparking: [#0088e1,#343434],
  etteplanmore: [#EF413D,#1CA4DD],
  fastems: [#414646,#ef3135,#fff],
  framery: [#0c8260],
  futurice: [#0AB587],
  gofore: [#FF8551],
  huld: [#00173A],
  innogiant: [#000000],
  intopalo: [#054E96,#05B2E1],
  kapina: [#f5b104],
  knowit: [#333333],
  mfiles: [#0070ce,#555555],
  multim: [#53b1e3,#0375b0,#343434],
  navigaattori: [#4E008E],
  netum: [#00a119],
  nokianpanimo: [#242021],
  poas: [#3A5DAE],
  polarsquad: [#000000],
  poppankki: [#00ef7f],
  profit: [#001A70],
  pyynikin: [#000],
  rare: [#000],
  ratekoulutus: [#2d519f],
  rdvelho: [#84BC26,#4C4D4F],
  riemurinne: [#2C3795],
  reaktor: [#000000],
  solita: [#DC1E32],
  tek: [#6bc],
  tamppi: [#000000],
  trey: [#FFCB03],
  tuni: [#4d008d],
  vincit: [#F04E30],
  wapice: [#006FAB],
  yit: [#0487B4],
  fonum: [#000000],
  opiskelijantampere: [#00aeef,#ec008c,#a6ce39,#6d6e71]
);

@each $name, $colors in $friends {
  ##{$name} {
    &:hover, &:focus {
      @for $i from 1 through length($colors) {
        .f#{$i} {
          @include transition(fill);
          $c: nth($colors, $i);
          fill: $c;
        }
      }
    }
  }
}

#eficode:not(:hover) .f2{ fill: rgba(0, 0, 0, 0.5); }
#fastems:not(:hover) .f3{ fill: rgba(0, 0, 0, 0.3); }
